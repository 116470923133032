import React, {CSSProperties, useState} from 'react';
import * as styles from './Header.module.less';
import {Link} from "react-router-dom";
import {NavMenu} from "./NavMenu";
import clsx from "clsx";
import {ThemeToggler} from "./ThemeToggler";
import {LuGem, LuMenuSquare, LuUserCircle2} from "react-icons/lu";

import {useQuery} from "react-query";
import {getWhoAmI} from "../../api/user";
import UseAnimations from 'react-useanimations';
import activity from 'react-useanimations/lib/activity';


type Props = {
    className?: string
};

const Spinner = () => {
    return <div className={clsx(styles.navAlign, )}><UseAnimations strokeColor={"currentColor"} animation={activity} size={25}/></div>
}

const LoginButton = () => {
    const currentUserQuery = useQuery('getWhoAmI', getWhoAmI);

    if (currentUserQuery.isLoading) {
        return <Spinner/>
    }

    if (currentUserQuery.data.is_auth) {
        return (
            // <Link to={"/v2/login"} className={clsx(styles.navBut, styles.iconSize)}>

            <NavMenu label={
                <NavBut>
                    <div className={clsx(styles.iconSize)}>
                        <LuUserCircle2/>
                    </div>
                </NavBut>}
            >
                <Link to={"/v2/logout"}>Выйти</Link>
            </NavMenu>
            // </Link>
        )
    } else {
        return <>
            <Link to={"/v2/login"} className={clsx(styles.navBut)}>
                Вход
            </Link>
            <Link to={"/v2/signup"} className={clsx(styles.navBut)}>
                Регистрация
            </Link>
        </>
            ;
    }
}


const UserMenu = () => {
    const currentUserQuery = useQuery('getWhoAmI', getWhoAmI);
    if (currentUserQuery.isLoading || !currentUserQuery.data.is_auth) {
        return <></>
    }

    return <>
        <a href={"/back/my_orders"} className={styles.navBut}>Мои заказы</a>
        <a href={"/back/my_subs"} className={styles.navBut}>Мои подписки</a>
    </>
}
const GlobalPopupError = () => {
    const currentUserQuery = useQuery('getWhoAmI', getWhoAmI);
    if (currentUserQuery.isError) {
        return <>{currentUserQuery.error}</>;

    }
}

const NavBut: React.FC<React.PropsWithChildren> = ({children}) => {
    return <div className={styles.navBut}>
        {children}
    </div>
}

const AdminMenu = () => {
    const currentUserQuery = useQuery('getWhoAmI', getWhoAmI);
    if (currentUserQuery.isLoading || currentUserQuery.data.role !== 1) {
        return <></>
    }

    return <>
        <NavMenu label={<NavBut>Label</NavBut>}>
            <div>Menu 1</div>
            <div>Menu 2</div>
            <div>Menu 2sfasdfafasfa af asdf asf a</div>
        </NavMenu>
        <NavMenu label={<NavBut>Основное меню</NavBut>}>
            <div>Menu Тесе</div>
            <div>Menu уще</div>
            <div>Menu список</div>
            <div>Menu список1</div>
            <div>Menu список2</div>
        </NavMenu>
    </>
}

const Brand = () => {
    return <Link to={"/v2"} className={styles.navBut}>

        <div className={clsx(styles.brand, styles.iconSize)}><LuGem/></div>
        Проекты

    </Link>
}

export const Header: React.FC<React.PropsWithChildren & Props> = ({children}) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={clsx(styles.mainMenu)}>
            <div className={styles.header}>
                <Brand/>
                <div className={clsx(styles.toggleButton, styles.navBut)} onClick={handleMenuToggle}>
                    <LuMenuSquare/>
                </div>
            </div>
            <div className={clsx(styles.navbarFluid, styles.content, !menuOpen && styles.displayMobileNone)}>

                <UserMenu/>
                <AdminMenu/>
                <GlobalPopupError/>

                <div className={clsx(styles.navRight, styles.navbarFluid)}>
                    <LoginButton/>
                    <ThemeToggler className={clsx(styles.navBut)}/>
                </div>
            </div>
        </div>
    );
};