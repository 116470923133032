import React from 'react';
import {useAtom} from "jotai";
import * as styles from './ThemeToggler.module.less';
import classNames from "classnames";
import {themeAtom} from "src/pages/Home/store";

export const ThemeToggler = ({className, ...props}) => {
    const [theme, setTheme] = useAtom(themeAtom)


    const eventHandler = () => {
        console.log('click', theme)
        if (theme === 'default') {
            setTheme('dark')
        } else {
            setTheme('default')
        }
        // setState(!state);

    }
    return <div onClick={eventHandler} className={classNames(styles.content, className)} {...props}/>
}